import { styled } from "@mui/material/styles";
import type { FCWithChildren } from "../../api-client/resources/types/sharedTypes";

const PageContainerUnstyled: FCWithChildren<{ className?: string }> = ({
	className,
	children,
}) => {
	return (
		<main id="page-container" className={className}>
			{children}
		</main>
	);
};

// PageContainer creates a sticky footer
export const PageContainer = styled(PageContainerUnstyled)`
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: var(--space-xl);

	@media (min-width: ${(props) => props.theme.breakpoints.values.sm}px) {
		padding: 0 0 var(--space-xl) 0;

		/* this margin-top is necessary to ensure that it pushes the page container below the two headers */
		margin-top: calc(var(--space-s) * 10);
	}
`;
