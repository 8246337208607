import {
	type AuthError,
	buyerLogin,
	type BuyerLoginBody,
	refreshBuyerToken,
	sendCode,
	type SendCodeBody,
	verifyCode,
} from "@getbread/api-client-auth-v2";
import { sub } from "date-fns";
import { tracking } from "../tracking/tracking";
import { getConfig } from "../utils/getConfig";
import { secondsToMinutesRoundedUp } from "../utils/dates";
import type { LoginResponse } from "./types";

type DeliveryMethod = "SMS" | "EMAIL";

class AuthApiError extends Error {
	constructor(response: AuthError) {
		super(response.message);
	}
}

class RetryApiError extends AuthApiError {
	remainingRetries: number;
	timeout: number;

	constructor(response: AuthError, attempts?: string, maxAttempts?: string, timeout?: string) {
		super(response);

		this.remainingRetries = Number(maxAttempts) - Number(attempts);
		this.timeout = secondsToMinutesRoundedUp(Number(timeout));
	}
}

class CodeLockoutError extends AuthApiError {}

class InvalidInputError extends AuthApiError {}

class InvalidJwtApiError extends Error {}

class UnauthenticatedBuyerNotFoundError extends AuthApiError {}

const filterError = (body: AuthError) => {
	switch (body.reason) {
		case "Invalid_Token":
			throw new RetryApiError(
				body,
				body.metadata?.attempts,
				body.metadata?.max_attempts,
				body.metadata?.lockout_duration,
			);
		case "Unauthenticated":
			throw new UnauthenticatedBuyerNotFoundError(body);
		case "Unauthenticated_Buyer_Not_Found": // legacy
		// eslint-disable-next-line no-fallthrough
		case "Unauthenticated_Buyer_Mismatch": // legacy
		case "Unauthenticated_Retry_With_PII":
			throw new InvalidInputError(body);
		case "Too_Many_Unverified_Codes_Sent":
			throw new CodeLockoutError(body);
		default:
			throw new AuthApiError(body);
	}
};

export {
	AuthApiError,
	RetryApiError,
	InvalidJwtApiError,
	InvalidInputError,
	CodeLockoutError,
	UnauthenticatedBuyerNotFoundError,
};

function buildSendTokenBody(
	deliveryMethod: DeliveryMethod,
	phone: string | undefined,
	email: string,
	acceptedAt: Date,
	languagePreference: string,
) {
	const configuredDisclosures =
		getConfig("logindisclosures")[deliveryMethod.toLowerCase() as Lowercase<DeliveryMethod>];

	const returnVal: SendCodeBody = {
		phone: deliveryMethod === "SMS" ? phone : undefined,
		email,
		deliveryMethod,
		languagePreference,
		disclosures: configuredDisclosures.map((d) => ({ type: d, acceptedAt })),
		uat: undefined,
	};

	return returnVal;
}

/**
 * Sends an authorization token to a user via either phone or email. Recieves a reference ID that can be sent to `authorize`
 * along with the token to verify a user.
 * v2: /api/auth/send-code, -refID+referenceID
 */
export const sendToken = async (
	deliveryMethod: DeliveryMethod,
	{ phone, email }: { phone?: string; email: string },
	languagePreference: string,
) => {
	const acceptedAt = sub(new Date(), { days: 1 });

	const body = buildSendTokenBody(deliveryMethod, phone, email, acceptedAt, languagePreference);

	if (getConfig("sandbox")) {
		switch (deliveryMethod) {
			case "SMS":
				body.uat = { auth: { phoneType: "VOIP", token: "1234" } };
				break;
			case "EMAIL":
				body.uat = { auth: { token: "1234" } };
				break;
			default:
				break;
		}
	}

	try {
		return (await sendCode(body)).data.referenceID;
	} catch (error) {
		return filterError(error as AuthError);
	}
};

/**
 * Takes a reference ID and token and authenticates (logs in) a user
 */
export const login = async (values: BuyerLoginBody): Promise<LoginResponse> => {
	try {
		const body = (await buyerLogin(values)).data;
		tracking.trackSuccessfulLogin();
		return {
			jwt: body.token,
			expiresAt: body.tokenExpiresAt,
			buyerId: body.buyerID,
		};
	} catch (error) {
		tracking.trackLoginFailure();
		return filterError(error as AuthError);
	}
};

/**
 * Takes a reference ID and token and authenticates a *new* number
 */
export const authenticate = async (refID: string, token: string) => {
	try {
		await verifyCode({ code: token, referenceID: refID });
		return true;
	} catch (error) {
		return filterError(error as AuthError);
	}
};

/**
 * Generates a new JWT with an extended expiry time
 */
export const refresh = async () => {
	try {
		const body = (await refreshBuyerToken()).data;
		return { jwt: body.token, expiresAt: body.tokenExpiresAt };
	} catch (error) {
		return filterError(error as AuthError);
	}
};
