export const defaultFlags = {
	"enable-billing-address": false,
	"enable-glba": false,
	"enable-maintenance-notification": false,
	"enable-cs-fraud-number": false,
	"mpEnableExpandedOTPSentMessage": false,
	"mpEnableRobotsMetaTag": false,
};

export type MemberPortalFlags = typeof defaultFlags;
